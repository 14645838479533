'use client';

import { Button } from '@/components/ui/button';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useEffect } from 'react';

export default function GlobalError({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) {
	useEffect(() => {
		Sentry.captureException(error);
	}, [error]);
	return (
		<html lang='en'>
			<head>
				<link rel='preconnect' href='https://fonts.googleapis.com' />
				<link
					rel='preconnect'
					href='https://fonts.gstatic.com'
					crossOrigin=''
				/>
				<link
					href='https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap'
					rel='stylesheet'
				/>
				<style>
					{`
						html {
							font-family: 'Figtree', sans-serif;
						}
					`}
				</style>
			</head>
			<body>
				<section className='container mx-auto max-w-xl justify-center items-center z-10 flex-1 pt-12 flex flex-col gap-6'>
					<h2 className='text-3xl font-extrabold max-w-[300px] leading-none uppercase text-center'>
						Something went wrong!
					</h2>
					<Button
						type='button'
						onClick={
							// Attempt to recover by trying to re-render the segment
							() => reset()
						}
					>
						Try again
					</Button>
					<Link
						href='https://airtable.com/appmIddQx198t4f5a/shrogHQPML7S3QNWb'
						target='_blank'
						aria-label='Link to a Give Feedback form'
						className='text-center underline text-purple-600'
					>
						Report the error
					</Link>
				</section>
			</body>
		</html>
	);
}
